@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.homepage-all{
  overflow-x: hidden;
}
@font-face {
  font-family: 'Open Sans ';
  src: url('./Homepage/open-sans-hebrew/OpenSansHebrew-Light.ttf') format('ttf');
      
  /* font-weight: 400; Regular weight */
  font-style: normal;
}

/* @font-face {
  font-family: 'Open Sans Hebrew';
  src: url('./Homepage/open-sans-hebrew//OpenSansHebrew-Bold.woff2') format('woff2'),
       url('./Homepage/open-sans-hebrew//OpenSansHebrew-Bold.woff') format('woff'),
       url('./Homepage/open-sans-hebrew//OpenSansHebrew-Bold.ttf') format('truetype');
  font-weight: 700; 
  font-style: normal;
} */
body {
  font-family: 'Open Sans', 'Roboto', 'Inter', sans-serif;
}
.bar{
  display: none;
}
.login a{
  text-decoration: none;
}
.banner-des a{
  text-decoration: none;
  color: black;
}
.navbar-all{
    background-color: #202020;
    height: 80px !important;
    background: #202020;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 20px;
}
.nav-list {
    display: flex;          
    list-style: none !important;
    gap: 40px;
  padding: 0;                    /* Remove default padding */
  margin: 0; 
  }
  .nav-right-con {
    display: flex;              
    align-items: center;           
    gap: 20px;                    
  }
  .nav-right-con .img-fluid{
    max-width: 100px;              /* Adjust image size as needed */
  height: auto;
  }
  
  
  .nav-list li {
    cursor: pointer;         
    color: white !important;
font-family: "Open Sans";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
 .eng{
    display: flex;
    align-items: center;
    gap: 10px;
 } 
 .eng p{
    color: #FFF;
font-family: "Open Sans";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;  
 }
 .eng p, .login p {
    margin: 0;                    
  }
.nav-con{
    display: flex;
    align-items: center;
justify-content: space-between; 
}
.globe{
    /* font-size: 100px; */
    color: white;
}
.nav-left-con{
    display: flex;
    align-items: center;
    gap: 32px;
}
.login p{
    color: #FFF;
font-family: "Open Sans";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.register button{
    padding: 12px 20px;
    align-items: center;
    color: #FFF;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
background: #565656;
border: none;
}
.service-des{
  padding-bottom: 90px;
}

/* end of navbar */

/* footer starts */

.footer-all{
    background-color: #202020;
    padding: 45px;

}
.f-logo{
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 15px;
}
.f-logo p{
    margin: 0;
    color: #FFF;
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 22.172px; /* 92.383% */
}
.footer-all p{
  color: #c4c4c4;
  font-family: "Open Sans";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 200% */
}
    .email-link {
        color: #FFF;
        font-family: "Open Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 200% */
                text-decoration: none;  /* Remove underline */
      }
.footer-des h3{
    color: #FFF;
-webkit-text-stroke-width: 0.20000000298023224;
-webkit-text-stroke-color: #FFF;
font-family: "Open Sans";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 111.111% */
padding-bottom: 15px;
}
.footer-des li{
    color: #c4c4c4;
font-family: "Open Sans";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 120% */
list-style: none;
padding-bottom: 10px;
}

.platform {
    list-style-type: none;     
    padding: 0;                
    margin: 0;                
  }
  
  .platform li {
    margin: 5px 0;             
  }
  .footer-reserve{
    background: #262626;
    height: 60px;
  }
  .footer-reserve p{
    text-align: center;
    color: #FFF;
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 114.286% */
letter-spacing: 0.28px;
opacity: 0.8;
padding: 22px;
  }

  /* timer */
  .countdown-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    background: #262626;
    padding: 15px;
  }
  .countdown-heading{
    font-family: "Open Sans";
font-size: 50px;
font-style: normal;
font-weight: 700;
/* line-height: 36px; 100% */
background: linear-gradient(90deg, #FFF 0%, #999 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }
  .timer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .time-container {
    display: flex;
    flex-direction: column; /* Make the time and label stack vertically */
    align-items: center;
    padding: 0 10px; /* Add padding between columns */
    border-radius: 4px;
background: rgba(0, 0, 0, 0.50);
  }
  
  
  .time {
    font-size: 3rem;
    color: #FFF; /* Red color for the timer value */
    font-weight: bold;
    border-radius: 4px;
/* background: rgba(0, 0, 0, 0.50); */
  }
  
  .label {
    color: #FFF;
    font-family: "Open Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 169.231% */
    padding-bottom: 18px;
  }
  
  .colon {
    padding: 0 5px;
    font-size: 2rem;
    color: #fff;
  }
  /* timer end */
  .banner{
   background-image: url("../pages/Homepage/Images/stripe.png");
    /* background: #262626; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .banner-des{
    padding: 90px;
  }
  .banner-des h1{
    font-family: "Open Sans";
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px; /* 118.519% */
    background: linear-gradient(90deg, #FFF 0%, #999 77.8%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }
  .banner-des h2{
    font-family: "Open Sans";
font-size: 54px;
font-style: normal;
font-weight: 700;
line-height: 64px; /* 118.519% */
background: linear-gradient(90deg, #FFF 0%, #999 77.8%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 30px;
  }
  .banner-des p{
    color: #FFF;
font-family: "Open Sans";
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: 29px; /* 152.632% */
opacity: 0.7;
padding-bottom: 30px;


  }
  .iphone img{
  width: 335px;
  /* height: 650px; */
  height: fit-content !important;
  }
  .globe-h {
    /* transition: transform 0.3s ease-in-out; Smooth transition */
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out; /* Smooth transition for both transform and color */
    color: #000; 
  }
  
  .globe-h:hover {
    transform: scale(1.2); /* Scale the icon when hovered */
    color: white !important;
    background-color: black !important;
  }
  .timer-des p{
    font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 34px; /* 170% */
background: linear-gradient(281deg, #1A1A1A 81.82%, #808080 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }
  .timer-des h2{
    font-family: "Open Sans";
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 50px; /* 125% */
opacity: 0.9;
background: linear-gradient(281deg, #1A1A1A 81.82%, #808080 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }
  .adjustment p{
    padding-bottom: 90px !important;
  }
  .iphone-des p{
    color: #000;
font-family: "Open Sans";
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 83.333% */
opacity: 0.6;
  }
  .iphone-des span{
    color: #1A1A1A;
font-family: "Open Sans";
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 26px; /* 81.25% */
  }

  .i-card{
    display: flex;
    align-items: center;
    gap: 40px;
    border-radius: 12px;
background: #FFF;
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
padding: 10px 20px 10px 40px;
margin-bottom: 8px;
  }
  .num span{
    color: #1A1A1A;
font-family: "Open Sans";
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .i-card-des h2{
    color: #1A1A1A;
font-family: "Open Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-bottom: 12px;
  }
  .i-card-des p{
    color: #000;
font-family: "Open Sans";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */
  }
  .banner-des button{
    display: flex;
padding: 12px 24px;
justify-content: center;
align-items: center;
gap: 2px;
color: #000;
font-family: "Open Sans";
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 8px;
background: #fff;
border: none;
  }
  /* .banner-des button:hover{
    color: #BEB6E9;
    background-color: #000;
  } */
  .half-con{
    border-radius: 26px;
background: linear-gradient(180deg, #F4F4F4 0%, #E9E7E7 100%);
padding: 20px 71px 0 71px; /* top right bottom left */
position: relative;


  }
  .half-img{
    background-image: url("../pages/Homepage/Images/half.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 415px;
  }
  .half-img-des{
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
        padding: 8px;
        bottom: 0;
        position: relative;
        top: 200px;
  }
  .half-img-des h2{
    color: #1A1A1A;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 125% */
padding-bottom: 10px;
padding-top: 25px;
  }
  .half-img-des p{
    color: #000;
font-family: "Open Sans";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 153.846% */
  }
  .logo-sec-des{
    display: flex;
    align-items: center;
    gap: 200px;
  }
  .logo-sec-des p{
    color: #000;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 22.172px; /* 184.765% */
margin: 0;
padding: 0;
  }
  .half-i-des h2{
    font-family: "Open Sans";
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 50px; /* 125% */
opacity: 0.9;
background: linear-gradient(281deg, #1A1A1A 81.82%, #808080 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 20px;
  }

  .transactions{
    display: flex;
    align-items: center;
    gap: 60px;
  }
  .globe-h{
    width: 34px;
    height: 34px;
  margin-bottom: 16px;
  padding: 7px; /* Adjust padding as needed */
  border: 1px solid rgba(0, 0, 0, 0.2); /* Light black border */
  border-radius: 50%; /* Optional: to make the border rounded */
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.20)); /* Drop shadow */
  }
  .transaction-col h3{
    color: #1A1A1A;
font-family: "Open Sans";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 111.111% */
padding-bottom: 8px;
  }
  .transaction-col p{
    color: #000;
font-family: "Open Sans";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 133.333% */
opacity: 0.6;
  }
  .lap-sec{
    border-radius: 32px 32px 0px 0px;
background: linear-gradient(167deg, #393939 0.93%, #181818 24.94%, #1B1B1B 50.77%, #181818 76.33%);
height:auto;

  }
  .lap-sec h1{
    text-align: center;
font-family: "Open Sans";
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 60px; /* 125% */
background: linear-gradient(90deg, #FFF 0%, #999 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-top: 65px;
/* padding-bottom: 25px; */

  }
  .info img{
    width: 36px;
height: 26px;
  }
  .lap-img{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 37px;
  }
  .peoples{
    display: flex;
    align-items: center;
    gap: 60px;
    padding-top: 35px;
  }
  .peoples h3{
    color: #FFF;
text-align: center;
font-family: "Open Sans";
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 66.667% */
padding-bottom: 32px;
  }
  .peoples h2{
    color: #FFF;
-webkit-text-stroke-width: 0.20000000298023224;
-webkit-text-stroke-color: #FFF;
font-family: "Open Sans";
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 90.909% */
text-align: center;
padding-bottom: 10px;
  }
  .peoples p{
    color: #FFF;
text-align: center;
font-family: "Open Sans";
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 141.176% */
opacity: 0.6;
  }
  .mobile-shade{
    border-radius: 40px;
background: #262626;
height: 360px;
  }
  .mobile-app p{
    font-family: Inter;
font-size: 42px;
font-style: normal;
font-weight: 600;
line-height: 52px; /* 123.81% */
background: linear-gradient(90deg, #FFF 0%, #999 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }
  .play-store{
    display: flex;
    align-items: center;
    gap: 16px;
background-color: #fff;
    border-radius: 10px;
    padding: 17px;
    border: none;
  }
  .play-store h3{
    color: #000;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 14.293px; /* 89.33% */
text-transform: uppercase;
margin: 0;
white-space: nowrap;
  }
  .soon{
    display: flex;
    align-items: center;
    gap: 24px;
    padding-top: 32px;
  }
  .store-icon{
    width: 28.068px;
height: 34.496px;
flex-shrink: 0;
  }
  .soon-all{
    padding: 90px;
  }
  .app-imgg{
    position: relative;
    bottom: 70px;
  }

  .white-line{
    background-image: url("../pages/Homepage/Images/line.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }








  /* Media Queries */
 
  
  @media only screen and (max-width: 480px) {
    .service-des{
      padding-bottom: 20px;
    }
    .form-contact h3{
      font-family: "Open Sans"!important;
font-size: 24px !important;
font-style: normal;
font-weight: 700;
line-height: 24.809px; /* 103.369% */
padding-bottom: 10px !important;
text-align: center !important;
    }
    .form-contact button{
      display: flex;
width: 325px;
height: 48px;
padding: 16px;
justify-content: center;
align-items: center;
border-radius: 6px;
background: linear-gradient(193deg, #393939 18.98%, #1B1B1B 56.79%, #181818 81.02%);
margin-bottom: 15px;
    }
    .form-contact {
      display: flex !important;
      justify-content: center !important; /* Horizontally center */
      align-items: center !important;     /* Vertically center */
    }
    .form-contact input{
      display: flex;
/* width: 325px !important; */
height: 48px !important;
padding: 16px !important;
align-items: center !important;
gap: 5.907px;
border-radius: 6px !important;
background: #EEE;
    }
    .form-contact p{
      text-align: center;
font-size: 13px !important;
font-style: normal;
font-weight: 400;
line-height: 23px; /* 176.923% */
background: linear-gradient(281deg, #1A1A1A 81.82%, #808080 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .info img {
      width: 86px;
      height: 26px;
  }
    .soon{
      display: block;
      margin-left: 50px;
    }
    .soon .play-store:nth-child(2) {
      margin-top: 10px;
    }
    .sidebar-logo{
      margin-bottom: 32px;
      padding-left: 27px;
    }
    
    .nav-left-con .eng,.login,.register{
      display: none;
    }
    .nav-list{
      display: none !important;
    }
    .banner-des h1{
      /* text-align: center; */
font-family: "Open Sans";
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 26px; /* 100% */
background: linear-gradient(90deg, #FFF 0%, #999 77.8%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .transactions {
      display: block; /* Change display to block for mobile view */
  }
    .banner-des h2{
      font-family: "Open Sans";
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 34px; /* 130.769% */
background: linear-gradient(90deg, #FFF 0%, #999 77.8%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .nav-list,.eng,.login,.register {
      display: none;
    }
    .bar{
      display: block;
      color: white;
      font-size: 15px;
      border-radius: 4px;
background: rgba(255, 255, 255, 0.10);
padding: 10px;
    }
    .banner-des p {
      color: #FFF;
font-family: "Open Sans";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 25px; /* 166.667% */
opacity: 0.7;
    }
    .banner-des button {
      padding: 12px 24px;
      color: #000;
      font-family: "Open Sans";
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      white-space: nowrap;
    }
    .banner-des {
      padding: 10px;
    }
    .countdown-heading {
      font-family: "Open Sans";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 23.608px; /* 131.155% */
background: linear-gradient(90deg, #FFF 0%, #999 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
white-space: nowrap;
    }
    .time {
      color: #FFF;
font-family: "Open Sans";
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 22px; /* 84.615% */
    }
    .label {
      color: #FFF;
font-family: "Open Sans";
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: 14.427px; /* 180.339% */
opacity: 0.7;
    }
    .time-container {
      padding: 4px 8px;

    }
    .timer-des p {
      font-family: "Open Sans";
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 25px; /* 166.667% */
background: linear-gradient(281deg, #1A1A1A 81.82%, #808080 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .timer-des h2 {
      font-family: "Open Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 136.364% */
opacity: 0.9;
background: linear-gradient(281deg, #1A1A1A 81.82%, #808080 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .iphone-des span {
      color: #1A1A1A;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 10.297px; /* 64.356% */
    }
    .iphone-des p {
      /* text-align: center; */
      color: #000;
font-family: "Open Sans";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 23px; /* 176.923% */
opacity: 0.6;
    }
    .i-card-des h2 {
      color: #1A1A1A;
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 18px; /* 128.571% */
    }
    .i-card {
      gap: 20px;
    }
    .half-i-des h2 {
      font-family: "Open Sans";
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 34px; /* 130.769% */
opacity: 0.9;
background: linear-gradient(281deg, #1A1A1A 81.82%, #808080 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-align: center;
    }
    .transaction-col h3 {
      color: #1A1A1A;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 125% */
    }
    .transaction-col p {
      color: #000;
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
opacity: 0.6;
    }
    .lap-sec h1{
      text-align: center;
font-family: "Open Sans";
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 34px; /* 130.769% */
background: linear-gradient(90deg, #FFF 0%, #999 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding: 30px;
    }
    .peoples h3{
      color: #FFF;
text-align: center;
font-family: "Open Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 100% */
padding-bottom: 12px;
    }
    .peoples h2{
      color: #FFF;
-webkit-text-stroke-width: 0.20000000298023224;
-webkit-text-stroke-color: #FFF;
font-family: "Open Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 112.5% */
opacity: 0.9;
padding-bottom: 8px;

    }
    .peoples p {
      color: #FFF;
text-align: center;
font-family: "Open Sans";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 23px; /* 176.923% */
opacity: 0.7;
    }
    .mobile-app p {
      font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 160% */
background: linear-gradient(90deg, #FFF 0%, #999 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-align: center;
white-space: nowrap;
padding-top: 15px;
    }
    .play-store {
      color: #000;
font-family: Roboto;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 8.919px; /* 89.191% */
text-transform: uppercase;

    }
    .store-icon {
      width: 17.916px;
height: 19.957px;
flex-shrink: 0;
    }
    .app-imgg {
      bottom: 0;
    }
    .mobile-shade {
      height: 100%;
    }
    .soon-all{
      padding: 1px;
    }
    .footer-all {
      padding: 15px;
    }
    .half-con {
      padding: 0;
    }
    .half-img-des h2 {
      color: #1A1A1A;
font-family: "Open Sans";
font-size: 11px;
font-style: normal;
font-weight: 700;
line-height: 14.587px; /* 132.61% */
    }
    .half-img-des p {
      color: #000;
font-family: "Open Sans";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 14.587px; /* 145.871% */
opacity: 0.6;
    }
    .logo-sec-des {
      gap: 135px;
    }
    .footer-reserve p {
      color: #FFF;
font-family: "Open Sans";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 160% */
letter-spacing: 0.2px;
opacity: 0.8;
    }
    .half-img-des {
      top: 243px;
    }
    .about-banner h2 {
      font-family: "Open Sans";
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 26px; /* 100% */
background: linear-gradient(90deg, #FFF 0%, #999 77.8%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .about-banner p{
      color: #FFF;
text-align: center;
font-family: "Open Sans";
font-size: 14px !important;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
width: 100% !important;
opacity: 0.7;
    }
    .about-des h3{
      font-family: "Open Sans";
font-size: 24px !important;
font-style: normal !important;
font-weight: 700;
line-height: 36px; /* 150% */
opacity: 0.9;
background: linear-gradient(281deg, #1A1A1A 81.82%, #808080 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .about-des{
      display: block;
      width: 100% !important;
    }
    .about-des p{
      font-family: "Open Sans";
font-size: 14px !important;
font-style: normal;
font-weight: 400;
line-height: 24px !important; /* 171.429% */
opacity: 0.7;
background: linear-gradient(281deg, #1A1A1A 81.82%, #808080 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
width: 100% !important;
    }
    .about-des-location h3{
      font-family: "Open Sans";
font-size: 24px !important;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 100% */
background: linear-gradient(90deg, #FFF 0%, #999 77.8%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
    }
    .about-des-location p{
      color: #FFF;
font-family: "Open Sans";
font-size: 14px !important;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
width: 100% !important;
    }
  }
  
  @media only screen and (min-width: 481px) and (max-width: 767px) {
   
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
   
  }
  

  /* About us Page */
  .about-banner{
    background-image: url("../pages/Homepage/Images/stripe.png");
    height: 429px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontally centers the content */
    justify-content: center; /* Vertically centers the content */
    text-align: center;

  }

  .about-banner h2{
    font-family: "Open Sans";
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px; /* 105% */
    background: linear-gradient(90deg, #FFF 0%, #999 77.8%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 18px;

  }
  .about-banner p{
    color: #FFF;
text-align: center;
font-family: "Open Sans";
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: 29px; /* 152.632% */
opacity: 0.7;
width: 861px;
  }

  .about-des{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
width: 808px;
flex-direction: column;
align-items: center;
gap: 32px;
  }
  .about-des h3{
    text-align: center;
font-family: "Open Sans";
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 42px; /* 105% */
opacity: 0.9;
background: linear-gradient(281deg, #1A1A1A 81.82%, #808080 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }
  .about-des p{
    text-align: center;
font-family: "Open Sans";
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: 29px; /* 152.632% */
background: linear-gradient(281deg, #1A1A1A 81.82%, #808080 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
width: 808px;
  }
  .about-des-location{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
background: #262626;
flex-direction: column;
gap: 32px;
padding: 20px;
  }
  .about-des-location h3{
    font-family: "Open Sans";
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 42px; /* 105% */
background: linear-gradient(90deg, #FFF 0%, #999 77.8%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }
  .about-des-location p{
    color: #FFF;
    text-align: center;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 145.455% */
    width: 553px;
  }
  .about-white-line{
    background-image: url("../pages/Homepage/Images/line.png");
   
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .form-contact h3{
    font-family: "Open Sans";
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 42px; /* 105% */
opacity: 0.9;
background: linear-gradient(281deg, #1A1A1A 81.82%, #808080 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 32px;
  }
  .form-contact p{
    font-family: "Open Sans";
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: 29px; /* 152.632% */
background: linear-gradient(281deg, #1A1A1A 81.82%, #808080 103.5%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding-bottom: 32px;

  }
  .form-contact button{
    display: flex;
height: 60px;
padding: 12px 20px;
justify-content: center;
align-items: center;
gap: 2px;
align-self: stretch;
border-radius: 8px;
background: #000;
border: none !important;
color: white !important;
background: linear-gradient(193deg, #393939 18.98%, #1B1B1B 56.79%, #181818 81.02%);

width: 90%;
  }
  .form-contact input{
    display: flex;
height: 52px;
padding: 20px;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 8px;
background: #EEE;
border: none !important;
margin-bottom: 16px;
width: 90%;
  }
  .form-contact textarea{
    display: flex;
height: 138px;
padding: 20px;
align-items: flex-start;
gap: 10px;
align-self: stretch;
border-radius: 8px;
background: #EEE;
margin-bottom: 16px;
border: none !important;
width: 90%;
  }
  .nav-list li a {
    color: #FFF;
    text-decoration: none;
    transition: color 0.3s;
  }

  .nav-list {
    list-style: none;
    display: flex;
    padding: 0;
  }
  
  .nav-item {
    position: relative;
  }
  
  /* Dropdown hidden by default */
  .dropdown {
    display: none;
    position: absolute;
    top: 100%; /* Drop it below the nav-item */
    left: 0;
    background-color: #c9c9c9e4;
    list-style: none;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    color: black !important;
  }
  
  .nav-item:hover .dropdown {
    display: block;
  }
  
  /* Styling for the dropdown items */
  .dropdown li {
    white-space: nowrap;
    padding: 5px 5px;
    color: #000 !important;
    border-radius: 5px;
  }
  
  .dropdown li:hover {
    background-color: #d8d8d8;
  }
  
  /* Main container for the sidebar */
.sidebar {
  height: 100%;
  width: 250px; /* Set the width of the sidebar */
  position: fixed;
  top: 0;
  left: -250px; /* Initially hidden (off-screen) */
  background-color: #111; /* Dark background */
  overflow-x: hidden;
  transition: 0.3s; /* Smooth transition */
  z-index: 1000; /* Make sure it stays on top */
  padding-top: 60px; /* Padding for content */
}

/* When sidebar is open */
.sidebar.open {
  left: 0; /* Move the sidebar into view */
}

/* Sidebar menu items */
.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px 20px;
}

.sidebar ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  display: block;
}

.sidebar ul li a:hover {
  background-color: #575757;
}

/* Styling the overlay (click outside to close the sidebar) */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 999; /* Sits behind the sidebar */
}

/* Style for the hamburger icon */
.bar {
  font-size: 20px;
  cursor: pointer;
  /* position: fixed; */
  top: 20px;
  left: 20px;
  z-index: 1100; /* On top of everything */
}


